//jsxhook
import { Store } from "cart/Stores"
import { NextPage } from "next"
import Head from "next/head"
import { DomainProduct } from "product/models/DomainProduct"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Flex } from "theme-ui"
import { alternateAndCanonicalUrlRelLinks } from "../components/AlternateHrefs"
import { appUrl, webNextPrismicApiUrl } from "../Config"
import { usePreferredLanguage, useStoreContext } from "../i18n/StoreHooks"
import { LayoutState } from "../layout/LayoutState"
import { GlobalProps, StoreProps } from "../next/GlobalProps"
import { PrismicPageParams } from "../routing/PrismicPageParams"
import { useAppDispatch } from "../State"
import logger from "../utils/logger"
import { PrismicDocumentAdapter } from "./PrismicAdapters"
import { PrismicLinkedDocumentContext } from "./PrismicLinkedDocumentContext"
import {
  PrismicDocument,
  PrismicLinkedDocument,
  PrismicSlice,
  PrismicSliceType
} from "./PrismicModels"
import {
  PrismicProductAttributeAggregator,
  PrismicProductCodeAggregator,
  PrismicProductByCategoryAggregator
} from "./PrismicProductAggregators"
import {
  PrismicProductsContext,
  ProductsByCategory
} from "./PrismicProductsContext"
import { PrismicRelatedContentBlocksContext } from "./PrismicRelatedContentBlocksContext"
import {
  ArticleListSlice,
  AudioPlayerSlice,
  BannerSlice,
  CardSlice,
  ContactFormSlice,
  CustomComponent,
  EmbedSilce,
  GoogleMapSlice,
  HeroStatementSlice,
  HeroTitleSentenceSlice,
  HorizontalRuleSlice,
  MarketingSocialPromotions,
  MediaSlice,
  NavigationBarSlice,
  ProductAndAssetSlice,
  ProductListCaptionSlice,
  PromotionsSlice,
  QuoteSlice,
  RelatedArticleListSlice,
  RelatedContentBlockSlice,
  SectionTitleSlice,
  SponsorshipFormSlice,
  StandfirstSlice,
  StatisticGridSlice,
  StatisticSlice,
  StoreDetailsSlice,
  SubscriptionFormSlice,
  TabContentViewer,
  TableSlice,
  TextSlice,
  WindowSlice
} from "./slices"
import { CompetitionHeader } from "./slices/CompetitionHeader"
import { FeatureSlice } from "./slices/FeatureSlice"
import {
  ProductRecommendationByAttribute,
  ProductRecommendationByIds,
  ProductRecommendationProvider,
  CategoryCarousel
} from "./slices/ProductRecommendation"
import { StickyHeader } from "./slices/StickyHeader"
import { TextListSlice } from "./slices/TextListSlice"

interface PrismicPageProps {
  params?: PrismicPageParams
  document: PrismicDocument
  linkedDocuments?: PrismicLinkedDocument[]
  relatedContentBlocks?: PrismicLinkedDocument[]
  products?: DomainProduct[]
  store: StoreProps
}

export const SliceRenderer: React.FC<{
  slices: PrismicSlice[]
}> = ({ slices }) => {
  // transparent menu
  const dispatch = useAppDispatch()

  const shouldMakeMenuTransparent =
    slices.find(it => it.primary.header_distance === "none") !== undefined

  useEffect(() => {
    if (shouldMakeMenuTransparent) {
      setTimeout(
        () => dispatch(LayoutState.actions.setMenuTransparency(true)),
        200
      )
    } else {
      setTimeout(
        () => dispatch(LayoutState.actions.setMenuTransparency(false)),
        200
      )
    }
  }, [slices])
  return (
    <ProductRecommendationProvider slices={slices}>
      {slices.map((slice, idx) => {
        const key = `${idx}-${slice.id || ""}`
        switch (slice.slice_type) {
          case PrismicSliceType.relatedContent:
            return <RelatedContentBlockSlice key={key} data={slice} />
          case PrismicSliceType.text:
            return <TextSlice key={key} data={slice} />
          case PrismicSliceType.media:
            return <MediaSlice key={key} data={slice} />
          case PrismicSliceType.card:
            return <CardSlice key={key} data={slice} />
          case PrismicSliceType.statementHero:
            return <HeroStatementSlice index={idx} key={key} data={slice} />
          case PrismicSliceType.titleSentenceHero:
            return <HeroTitleSentenceSlice index={idx} key={key} data={slice} />
          case PrismicSliceType.banner:
            return <BannerSlice index={idx} key={key} data={slice} />
          case PrismicSliceType.standFirst:
            return <StandfirstSlice key={key} data={slice} />
          case PrismicSliceType.window:
            return <WindowSlice key={key} data={slice} />
          case PrismicSliceType.embed:
            return <EmbedSilce key={key} data={slice} />
          case PrismicSliceType.horizontalRule:
            return <HorizontalRuleSlice key={key} data={slice} />
          case PrismicSliceType.sectionTitle:
            return <SectionTitleSlice key={key} data={slice} />
          case PrismicSliceType.productListCaption:
            return <ProductListCaptionSlice key={key} data={slice} />
          case PrismicSliceType.navigationBar:
            return <NavigationBarSlice key={key} data={slice} />
          case PrismicSliceType.marketingChannelPromotion:
            return <MarketingSocialPromotions key={key} data={slice} />
          case PrismicSliceType.articleList:
            return <ArticleListSlice key={key} data={slice} />
          case PrismicSliceType.relatedArticleList:
            return <RelatedArticleListSlice key={key} data={slice} />
          case PrismicSliceType.quote:
            return <QuoteSlice key={key} data={slice} />
          case PrismicSliceType.statistic:
            return <StatisticSlice key={key} data={slice} />
          case PrismicSliceType.statisticGrid:
            return <StatisticGridSlice key={key} data={slice} />
          case PrismicSliceType.table:
            return <TableSlice key={key} data={slice} />
          case PrismicSliceType.googleMap:
            return <GoogleMapSlice key={key} data={slice} />
          case PrismicSliceType.promotionCards:
            return <PromotionsSlice key={key} data={slice} />
          case PrismicSliceType.productRecommendationByAttribute:
            return <ProductRecommendationByAttribute key={key} data={slice} />
          case PrismicSliceType.productRecommendationSectionByIds:
            return <ProductRecommendationByIds key={key} data={slice} />
          case PrismicSliceType.productRecommendationIds:
            return null
          case PrismicSliceType.productsByCategory:
            return <CategoryCarousel key={key} data={slice} />
          case PrismicSliceType.form:
            switch (slice.primary.form) {
              case "contact-us":
                return <ContactFormSlice key={key} data={slice} />
              case "newsletter":
                return <SubscriptionFormSlice key={key} data={slice} />
              case "newsletter-corporate-is":
                const corporateAudienceId = "ed78ca7cfc"
                return (
                  <SubscriptionFormSlice
                    key={key}
                    data={slice}
                    audienceId={corporateAudienceId}
                  />
                )
              case "sponsorships":
                return <SponsorshipFormSlice key={key} data={slice} />
              default:
                return null
            }
          case PrismicSliceType.mailingListForm:
            switch (slice.primary.form) {
              case "newsletter":
                return <SubscriptionFormSlice key={key} data={slice} />
              case "newsletter-corporate-is":
                const corporateAudienceId = "ed78ca7cfc"
                return (
                  <SubscriptionFormSlice
                    key={key}
                    data={slice}
                    audienceId={corporateAudienceId}
                  />
                )
              default:
                return null
            }
          case PrismicSliceType.store_details:
            return <StoreDetailsSlice key={key} data={slice} />
          case PrismicSliceType.custom_component:
            return <CustomComponent key={key} data={slice} />
          case PrismicSliceType.product_and_asset:
            return <ProductAndAssetSlice key={key} data={slice} />
          case PrismicSliceType.competitionHeader:
            return <CompetitionHeader key={key} data={slice} />
          case PrismicSliceType.stickyHeader:
            return <StickyHeader key={key} data={slice} />
          case PrismicSliceType.featureSlice:
            return <FeatureSlice key={key} data={slice} />
          case PrismicSliceType.textListSlice:
            return <TextListSlice key={key} data={slice} />
          case PrismicSliceType.audio_player:
            return <AudioPlayerSlice key={key} data={slice} />
          case PrismicSliceType.tabContentViewer:
            return <TabContentViewer key={key} data={slice} />
          default:
            return null
        }
      })}
    </ProductRecommendationProvider>
  )
}

const fetchProductsForDocument = async (
  document: PrismicDocument,
  store: Store
) => {
  const codeAggregator = new PrismicProductCodeAggregator()
  const attrAggregator = new PrismicProductAttributeAggregator()
  const codes = codeAggregator.aggregateMany([document])
  const attributes = attrAggregator.aggregateMany([document])
  return await fetch(webNextPrismicApiUrl("fetchProductsForDocument"), {
    method: "POST",
    body: JSON.stringify({
      codes,
      attributes,
      store
    }),
    headers: {
      "Content-Type": "application/json"
    }
  })
}

export const PrismicMetaTags: React.FC<{ document: PrismicDocument }> = ({
  document
}) => {
  const language = usePreferredLanguage()
  const { country } = useStoreContext()
  const { t } = useTranslation("layout")
  const adapter = new PrismicDocumentAdapter(document)

  const metaTitle = [adapter.metaTitle(""), "|", t("66north")].join(" ")

  const metaDescription = adapter.metaDescription("")

  const metaImage = adapter.metaImage("")

  const url = appUrl(country, adapter.path(adapter.uid))

  const noIndexEnabled = adapter.noIndexEnabled(undefined)

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="twitter:card" content={metaDescription} />
      <meta name="twitter:site" content="@66north" />
      <meta name="twitter:creator" content="@66north" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:type" content="article" />
      <meta property="og:locale" content={language} />
      {alternateAndCanonicalUrlRelLinks({
        en: adapter.path(),
        is: adapter.path()
      })}
      {noIndexEnabled && <meta name="robots" content="noindex" />}
    </Head>
  )
}

export const PrismicComponent: React.FC<PrismicPageProps> = ({
  params,
  document,
  relatedContentBlocks,
  linkedDocuments,
  ...props
}) => {
  const emptyResponse = {
    products: []
  }
  const [products, setProducts] = useState<DomainProduct[]>([])
  const {
    store: { currentStore }
  } = props

  useEffect(() => {
    fetchProductsForDocument(document, currentStore)
      .then(r => r.json() || emptyResponse)
      .then(it => {
        setProducts(it.products)
      })
      .catch(e => logger.warn("Failed to fetch product data", e))
  }, [document])

  return (
    <React.Fragment>
      <PrismicMetaTags document={document} />

      <Flex sx={{ flexDirection: "row", flexWrap: "wrap" }} as="article">
        <PrismicLinkedDocumentContext.Provider value={linkedDocuments || []}>
          <PrismicRelatedContentBlocksContext.Provider
            value={relatedContentBlocks || []}
          >
            <PrismicProductsContext.Provider
              value={{
                products
              }}
            >
              <SliceRenderer slices={document.data?.body || []} />
            </PrismicProductsContext.Provider>
          </PrismicRelatedContentBlocksContext.Provider>
        </PrismicLinkedDocumentContext.Provider>
      </Flex>
    </React.Fragment>
  )
}

export const PrismicPage: NextPage<PrismicPageProps & GlobalProps> = ({
  ...props
}) => {
  return <PrismicComponent {...props} />
}

export default PrismicPage
